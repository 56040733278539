<template lang="pug">
    section#acessoRapido
        .wrapper
            h2 Acesso Rápido
            //- p.sub Lorem ipsum
            ul
                li(v-for="item in contents.content")
                    router-link(:to="{ name: 'Pagina', params: { slug: item.browsers[0].slug } }", v-if="!item.isExternal && item.browsers")
                        div.icon
                            Imagem(:contents="item.image")
                        div.info
                            h3 {{ item.title.pt }}
                            // p(v-if="item.description.pt") {{ item.description.pt }}
                    a(:href="item.link.pt", target="_blank", rel="noopener noreferrer", v-else-if="item.isExternal && item.link")
                        div.icon
                            Imagem(:contents="item.image")
                        div.info
                            h3 {{ item.title.pt }}
                            // p {{ item.description.pt }}
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'section-acesso-rapido',
  props,
  components
}
</script>

<style lang="stylus" scoped src="./AcessoRapido.styl"></style>
